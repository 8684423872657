import classnames from "classnames";
import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
import { useRecoilValue } from "recoil";
import { SelectQuestionModal } from "../../components/SelectQuestionModal";
import { LANGUAGES } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import {
  authUser,
  exam,
  selectedDepartmentObject,
} from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import "../../styles/layouts.scss";
import { YEARS } from "../../utilities/constants";
import { choiceLabel, getLanguageName, years } from "../../utilities/helpers";
import { Button } from "../buttons";
import { Markup } from "../displays";
import { Input, Select } from "../inputs";
import { Loader } from "../Loaders/index";

export const Selector = ({
  questions,
  allQuestions,
  onQuestionUpDown,
  deleteQuestion,
  onChange,
  returnEvent,
  disabled,
  language: languageFromProp,
}) => {
  const values = [...questions];
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mode, setMode] = useState("");
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMarks, setSelectedMarks] = useState();
  const selectedExam = useRecoilValue(exam);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedSet, setSelectedSet] = useState();
  const recoilAuth = useRecoilValue(authUser);
  const [language, setLanguage] = useState({ ...languageFromProp });
  const selectedDepart = useRecoilValue(selectedDepartmentObject);

  const { getSubjectByDepartment, getChaptersBySubject, getTopicsByChapter } =
    useData();

  useEffect(() => {
    setLanguage(languageFromProp);
  }, [languageFromProp]);

  useEffect(() => {
    const getAllowedDepartments = async () => {
      const departs = await instance.get(
        endpoints.getAllowedDepartments +
          `?exam=${
            selectedExam.split("/")[1]
          }&departmentPermissions=${recoilAuth.departmentPermissions.toString()}`
      );
      setDepartments(departs.data);
    };

    getAllowedDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExam]);

  const selectDepart = async (department) => {
    setSelectedTopic("");
    setSelectedChapter("");
    setSelectedSubject("");
    setSubjects([]);
    setSelectedDepartment(department);
    const subjects = await getSubjectByDepartment(department);
    setSubjects(subjects);
  };

  const selectSubject = async (subject) => {
    setSelectedChapter("");
    setSelectedTopic("");
    setSelectedSubject(subject);
    const chapter = await getChaptersBySubject(subject);
    setChapters(chapter);
  };

  const selectChapter = async (chapter) => {
    setSelectedChapter(chapter);
    const topic = await getTopicsByChapter(chapter);
    setTopics(topic);
  };

  const fetchQuestions = async () => {
    setLoading(true);
    let url = `?department=${selectedDepartment}`;
    if (selectedSubject) url += `&subject=${selectedSubject}`;
    if (selectedChapter) url += `&chapter=${selectedChapter}`;
    if (selectedTopic) url += `&topic=${selectedTopic}`;
    if (selectedYear) url += `&year=${selectedYear}`;
    if (mode) url += `&mode=${mode}`;
    if (selectedSet) url += `&set=${selectedSet}`;
    if (selectedMarks) url += `&marks=${selectedMarks}`;
    if (language) url += `&language=${language.value}`;

    const res = await instance.get(`${endpoints.getQuestions}/${url}`);
    setData(res.data);
    setLoading(false);
  };

  function closeDialog() {
    setSelected([]);
    setShow(false);
  }

  function setQuestions() {
    if (returnEvent) onChange(selected);
    else onChange((previous) => [...previous, ...selected]);
    closeDialog();
  }

  const onUp = (index) => () => {
    [values[index], values[index - 1]] = [values[index - 1], values[index]];
    onQuestionUpDown(values);
  };

  const onDown = (index) => () => {
    [values[index], values[index + 1]] = [values[index + 1], values[index]];
    onQuestionUpDown(values);
  };

  const onDelete = (index) => () => {
    if (returnEvent) deleteQuestion(values.filter((_, i) => i !== index));
    else onChange(values.filter((_, i) => i !== index));
  };

  const exists = (question) =>
    selected.some((value) => value._id === question._id);

  const onSelect = (question) => () => {
    if (exists(question))
      return setSelected((p) => p.filter((q) => q._id !== question._id));
    setSelected((previous) => [...previous, question]);
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <caption>List of Questions</caption>

        <div>
          <Button onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? "Hide Details" : "Show Details"}
          </Button>
        </div>
      </div>

      <div className="overflow-auto" style={{ maxHeight: "500px" }}>
        <table className="table table-bordered caption-top align-middle">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Question</th>
              <th className="text-center">Marks +/-</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {questions?.map((question, index) => {
              const isContentAvilable = Boolean(
                question?.text[languageFromProp.value]
              );

              return (
                <tr
                  className={classNames("", {
                    "bg-red-100": !isContentAvilable,
                  })}
                  key={index}
                >
                  <td className="text-center">{index + 1}</td>

                  {isContentAvilable ? (
                    <td className="text-ellipsis">
                      <div className="d-flex gap-3">
                        <div className={showDetails ? "w-50" : "w-100"}>
                          <Markup
                            className="mb-0"
                            latex={question?.text[languageFromProp.value]}
                          />
                        </div>
                      </div>

                      {showDetails && (
                        <>
                          <br />

                          <div className="row view-questions-data">
                            <div className="col">
                              <div className="font-semibold"> Subject:</div>
                              <div> {question?.subject?.title}</div>
                            </div>

                            <div className="col question-box-answer">
                              <div className="font-semibold">Answer:</div>
                              {question.choices &&
                              question?.choices?.length > 0 ? (
                                <ol type="a">
                                  {question.choices.map((choice, index) => (
                                    <li
                                      key={index}
                                      className={`choice ${
                                        choice.answer ? "correct-answer" : ""
                                      }`}
                                    >
                                      <Markup
                                        latex={
                                          choice.text[languageFromProp.value]
                                            ?.length
                                            ? choice.text[
                                                languageFromProp.value
                                              ]
                                            : "N/A"
                                        }
                                        className="mb-0"
                                      />
                                    </li>
                                  ))}
                                </ol>
                              ) : (
                                <Markup
                                  latex={
                                    question.answer[languageFromProp.value]
                                      ?.length
                                      ? question.answer[languageFromProp.value]
                                      : "N/A"
                                  }
                                  className="mb-0"
                                />
                              )}
                            </div>

                            <div className="col">
                              <div className="font-semibold">Set:</div>
                              {question?.set ? question.set : "N/A"}
                            </div>

                            <div className="col">
                              <div className="font-semibold"> Mode:</div>
                              <div style={{ textTransform: "uppercase" }}>
                                {question?.mode}
                              </div>
                            </div>

                            <div className="col">
                              <div className="font-semibold">
                                {" "}
                                Available in Languages:
                              </div>

                              <div className="flex justify-center gap-x-1 mt-1">
                                {Object.keys(question.text).map((lang) =>
                                  question.text[lang].length ? (
                                    <div className="capitalize bg-gray-400 text-white px-2 py-1 rounded-sm text-xs">
                                      {getLanguageName(lang)}
                                    </div>
                                  ) : null
                                )}
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="font-semibold">Solution:</div>

                            <div className="">
                              <Markup
                                latex={
                                  question.solution.text[languageFromProp.value]
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </td>
                  ) : (
                    <td className="text-red-700 font-medium">
                      ** This question in not available in '
                      {languageFromProp.label}'' language. **
                    </td>
                  )}

                  <td className="text-center">
                    {question?.markingRule?.positive}
                    {` `}/{`  `}
                    {question?.markingRule?.negative}
                  </td>

                  <td>
                    <span className="action delete" onClick={onDelete(index)}>
                      &times;
                    </span>

                    {index > 0 && (
                      <span className="action up" onClick={onUp(index)}>
                        &#x02191;
                      </span>
                    )}

                    {index < questions.length - 1 && (
                      <span className="action down" onClick={onDown(index)}>
                        &#x02193;
                      </span>
                    )}
                    <Link to={`/view/question/${question._id}`} target="_blank">
                      <i
                        style={{ fontSize: "1.4rem", cursor: "pointer" }}
                        className="action bi bi-eye-fill"
                      />
                    </Link>
                  </td>
                </tr>
              );
            })}

            <tr>
              <td className="text-center fw-bold">#</td>
              <td className="fw-bold">Total Marks</td>
              <td className="text-center fw-bold w-10">
                +
                {questions.reduce(
                  (prev, cur) =>
                    parseInt(Number(prev)) +
                    parseFloat(Number(cur?.markingRule?.positive)),
                  0
                )}
                {` `}/{` `}
                {questions.reduce(
                  (prev, cur) =>
                    (
                      parseFloat(Number(prev)) +
                      parseFloat(Number(cur?.markingRule?.negative))
                    ).toFixed(3),
                  0
                )}
                -
              </td>
              <td className="fw-bold">###</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <Button disabled={disabled} width="25" onClick={() => setShow(true)}>
          Add Question
        </Button>
      </div>

      <SelectQuestionModal
        title="Select Questions"
        size="fullscreen"
        footerVisible
        show={show}
        onClose={closeDialog}
        onDone={setQuestions}
      >
        <div className="mx-5">
          <div className="flex flex-wrap gap-x-8">
            <div className="!w-60">
              <Select
                label="Department"
                value={selectedDepartment}
                onChange={selectDepart}
                vertical
              >
                {departments?.map((sub) => (
                  <option key={sub?.code} value={sub?._id}>
                    {sub.title}
                  </option>
                ))}
              </Select>
            </div>

            <div className="!w-52">
              <Select
                label="Subject"
                value={selectedSubject}
                onChange={selectSubject}
                vertical
              >
                {subjects?.map((subject) => (
                  <option key={subject.code} value={subject._id}>
                    {subject.title}
                  </option>
                ))}
              </Select>
            </div>

            <Select
              label="Chapter"
              value={selectedChapter}
              onChange={selectChapter}
              vertical
            >
              {chapters?.map((chapter) => (
                <option key={chapter.code} value={chapter._id}>
                  {chapter.title}
                </option>
              ))}
            </Select>

            <div className="!w-60">
              <Select
                label="Topic"
                value={selectedTopic}
                onChange={setSelectedTopic}
                vertical
              >
                {topics?.map((topic) => (
                  <option key={topic.code} value={topic._id}>
                    {topic.title}
                  </option>
                ))}
              </Select>
            </div>

            <div className="!w-40">
              <Select
                label="Year"
                value={selectedYear}
                onChange={setSelectedYear}
                vertical
              >
                {YEARS.map(({ name, value }) => (
                  <option value={value}>{name}</option>
                ))}

                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </div>

            <div className="!w-40">
              <Select
                label="Set"
                value={selectedSet}
                onChange={(e) => setSelectedSet(e)}
                vertical
              >
                {[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
                  34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
                  49, 50,
                ].map((set) => (
                  <option key={set} value={set}>
                    {set}
                  </option>
                ))}
              </Select>
            </div>

            <Select label="Mode" value={mode} onChange={setMode} vertical>
              <option value="answer">Answer</option>
              <option value="mcq">MCQ</option>
              <option value="msq">MSQ</option>
              <option value="subjective">Subjective</option>
            </Select>

            <div className="!w-60">
              <Input
                value={selectedMarks}
                label="Total marks"
                onChange={setSelectedMarks}
              />
            </div>

            <div className="!mt-1">
              <h6 className="mb-1 font-semibold">Select Question Language</h6>
              <ReactSelect
                onChange={(e) => setLanguage(e)}
                options={selectedDepart?.languages?.map((lang) => {
                  const label = Object.keys(LANGUAGES).find(
                    (key) => LANGUAGES[key] === lang
                  );
                  return {
                    value: lang,
                    label: label.replace(/^./, label[0].toUpperCase()),
                  };
                })}
                value={language}
                placeholder="Select Language"
                className="w-52"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end -mt-6">
            <Button
              disabled={!selectedDepartment}
              color="success"
              width="25"
              onClick={fetchQuestions}
              className="mb-3"
            >
              See Questions
            </Button>
          </div>
          <hr className="mb-4" />

          {!loading ? (
            data.map(
              (question) =>
                !allQuestions?.some((q) => q._id === question._id) && (
                  <div
                    key={question._id}
                    className={classnames(
                      "question",
                      exists(question) && "selected"
                    )}
                    onClick={onSelect(question)}
                  >
                    <div className="flex justify-end -mt-3">
                      <Link
                        to={`/view/question/${question._id}`}
                        target="_blank"
                      >
                        <i
                          style={{ fontSize: "1.4rem", cursor: "pointer" }}
                          className="action bi bi-eye-fill"
                        />
                      </Link>
                    </div>

                    <Markup
                      className="mb-0"
                      latex={question.text[language.value]}
                    />

                    <div className="col question-box-answer mt-4">
                      <div className="font-semibold">Answer:</div>
                      <div className="mt-1">
                        {question.choices.map((choice, index) => {
                          return (
                            <div className="flex items-center gap-x-2">
                              <div className="font-bold">
                                {choiceLabel(index)}
                              </div>
                              <Markup
                                latex={
                                  choice.text[languageFromProp.value]?.length
                                    ? choice.text[languageFromProp.value]
                                    : "N/A"
                                }
                                className={classnames("mb-0", {
                                  "font-extrabold bg-slate-200 p-1 rounded-md":
                                    choice.answer,
                                })}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="footer mt-4">
                      {/* <Score marks={question.marks} /> */}
                      <p>
                        +{question?.markingRule?.positive}/-
                        {question?.markingRule?.negative}
                      </p>

                      <span className="spacer" />
                      <p>Test Series Count: {question.testSeries.length}</p>

                      <span className="spacer" />
                      <p> Subject: {question?.subject?.title}</p>

                      <span className="spacer" />
                      <p>Quiz Count: {question.quizzes.length}</p>

                      <span className="spacer" />
                      <p>
                        Mode:
                        <span className="text-uppercase">
                          {" "}
                          {question.mode}{" "}
                        </span>
                      </p>

                      <span className="spacer" />
                      <p>Year: {question.year}</p>
                    </div>
                  </div>
                )
            )
          ) : (
            <Loader />
          )}
        </div>
      </SelectQuestionModal>
    </div>
  );
};
