import React, { useState } from "react";

import { Submit } from "../components/buttons";
import { Input } from "../components/inputs";
import { endpoints } from "../constants/Endpoints";
import useUserActions from "../hooks/useUserActions";
import { instance } from "../services/https/inceptors";
import { showToast } from "../utilities/toast";

const Password = () => {
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const { logout } = useUserActions();

  const onPasswordChange = async (event) => {
    event.preventDefault();
    await instance.put(endpoints.changePassword, { password });
    logout();
    showToast({ type: "error", message: "Password successfully changed!" });
  };

  return (
    <form onSubmit={onPasswordChange}>
      <Input
        id="password"
        type="password"
        value={password}
        onChange={setPassword}
      >
        Password
      </Input>

      <Input
        id="confirm"
        type="password"
        value={confirmation}
        onChange={setConfirmation}
      >
        Confirm Password
      </Input>

      <Submit
        disabled={
          !password.trim() || !confirmation.trim() || password !== confirmation
        }
      >
        Change Password
      </Submit>
    </form>
  );
};

const PasswordPage = () => (
  <div className="h-100 d-flex justify-content-center align-items-center">
    <div className="card" style={{ width: "350px" }}>
      <div className="card-body p-5">
        <Password />
      </div>
    </div>
  </div>
);

export default PasswordPage;
