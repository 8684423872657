import { default as classnames } from "classnames";
import { MathfieldElement } from "mathlive";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { SelectQuestionModal } from "./SelectQuestionModal";

/**
 * Create a primary button
 */

const Button = ({
  width,
  color = "primary",
  className,
  transparent,
  children,
  custom,
  ...props
}) => (
  <button
    type="button"
    className={classnames(
      custom ? "" : `btn btn-${color}`,
      width && `w-${width}`,
      className
    )}
    style={{
      background: transparent ? "transparent" : `rgb(2, 79, 16)`,
      color: transparent ? "black" : "white",
      border: "none",
    }}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  width: PropTypes.oneOf(["100", "75", "50", "35", "25"]),
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

/**
 * Creates a submit button with predefined formatting
 */

const Submit = ({
  width = "100",
  disabled = false,
  className,
  children = "Submit",
  ...props
}) => (
  <button
    type="submit"
    className={classnames("btn", `w-${width}`, className)}
    style={{ background: `rgb(2, 79, 16)`, color: "white" }}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

Submit.propTypes = {
  width: PropTypes.oneOf(["100", "75", "50", "25"]),
  disabled: PropTypes.bool,
  children: PropTypes.string,
  className: PropTypes.string,
};

/**
 * Displays a modal to enter equation when clicked
 */

const Equation = ({ inputRef, onEntered }) => {
  const mathFieldRef = useRef();

  const [active, setActive] = useState(false);
  const [equation, setEquation] = useState("");

  const mathFieldElement = new MathfieldElement({
    defaultMode: "math",
  });

  mathFieldElement.addEventListener("input", (event) =>
    setEquation(event.target.value)
  );

  function openDialog() {
    mathFieldRef.current.appendChild(mathFieldElement);
    setActive(true);
  }

  function closeDialog() {
    mathFieldRef.current.removeChild(mathFieldRef.current.children[0]);
    setEquation("");
    setActive(false);
    inputRef.current.focus();
  }

  function getLatex() {
    onEntered(`/eqtn\\mathrm{${equation.replace(/\s+/g, "")}}`);
    closeDialog();
  }

  return (
    <>
      <SelectQuestionModal
        title="Enter Equation"
        show={active}
        onClose={closeDialog}
        onDone={getLatex}
        footerVisible
      >
        <div ref={mathFieldRef} className="my-3" />
      </SelectQuestionModal>

      <Button className="square" onClick={openDialog}>
        &Sigma;
      </Button>
    </>
  );
};

Equation.propTypes = {
  inputRef: PropTypes.object,
  onEntered: PropTypes.func,
};

const ActiveButton = ({ active, className, onClick }) => (
  <span className={classnames("action", className)} onClick={onClick}>
    {active ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-eye-slash-fill"
        viewBox="0 0 16 16"
      >
        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-eye-fill"
        viewBox="0 0 16 16"
      >
        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
      </svg>
    )}
  </span>
);

ActiveButton.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const EditButton = ({ editing, className, onClick }) => (
  <span className={classnames("action", className)} onClick={onClick}>
    {editing ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-check-square-fill"
        viewBox="0 0 16 16"
      >
        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-pencil-fill"
        viewBox="0 0 16 16"
      >
        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
      </svg>
    )}
  </span>
);

EditButton.propTypes = {
  editing: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export { ActiveButton, Button, EditButton, Equation, Submit };
