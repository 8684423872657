import classNames from "classnames";
import { useEffect, useState } from "react";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { Admin as Role } from "../../utilities/constants";
import { showToast } from "../../utilities/toast";

export const EditAdminModal = ({
  admin,
  exams,
  show,
  onClose,
  onReset,
  onUpdated,
}) => {
  const [selectedExams, setSelectedExams] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [departments, setDepartments] = useState({});

  useEffect(() => {
    if (show) {
      setSelectedExams(admin.examPermissions.map((exam) => exam._id));
      setSelectedDepartments(
        admin.departmentPermissions.map((dept) => dept._id)
      );

      if (
        admin.role !== Role.MASTER &&
        admin.role !== Role.EXAM &&
        admin.examPermissions &&
        admin.examPermissions.length > 0
      ) {
        const getExamDepartments = async () => {
          const res = await instance.get(
            `${endpoints.getDepartmentsByExams}?exams=${admin.examPermissions
              .map((exam) => exam._id)
              .join(",")}`
          );

          setDepartments(res.data);
        };

        getExamDepartments();
      }
    } else {
      setSelectedExams([]);
      setSelectedDepartments([]);
      setDepartments({});
    }
  }, [show, admin]);

  const getExamCode = (examId) => {
    return exams.find((exam) => exam._id === examId)?.code;
  };

  const onExamChange = async (event) => {
    const { checked, value } = event.target;

    if (checked) {
      setSelectedExams((prev) => [...prev, value]);

      if (admin.role !== Role.MASTER && admin.role !== Role.EXAM) {
        const res = await instance.get(
          `${endpoints.getDepartmentsByExam}?exam=${value}`
        );

        setDepartments((prev) => {
          return { ...prev, [value]: res.data };
        });
      }
    } else {
      setSelectedExams((prev) => prev.filter((item) => item !== value));

      if (admin.role !== Role.MASTER && admin.role !== Role.EXAM) {
        setDepartments((prev) => {
          const clone = { ...prev };
          delete clone[value];
          return clone;
        });
      }
    }
  };

  const onDepartmentChange = (event) => {
    const { checked, value } = event.target;

    if (checked) {
      setSelectedDepartments((prev) => [...prev, value]);
    } else {
      setSelectedDepartments((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      _id: admin._id,
      examPermissions: selectedExams,
      departmentPermissions: selectedDepartments,
    };

    try {
      const res = await instance.put(endpoints.updateAdmin, data);

      showToast({
        type: "success",
        message: "Succssfully updated the permissions",
      });

      onUpdated(res.data);
    } catch (err) {
      showToast({
        type: "error",
        message: err.response.data,
      });
    }
  };

  const handleReset = async () => {
    const data = {
      _id: admin._id,
      examPermissions: [],
      departmentPermissions: [],
    };

    try {
      const res = await instance.put(endpoints.updateAdmin, data);

      showToast({
        type: "success",
        message: "Successfully reset the admin permissions",
      });

      setSelectedExams([]);
      setSelectedDepartments([]);
      onReset(res.data);
    } catch (err) {
      showToast({
        type: "error",
        message: err.response.data.message,
      });
    }
  };

  return (
    <div className={classNames("backdrop fade", show && "show")}>
      <div className="modal-dialog modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="text-2xl font-semibold">
              Edit Permissions - {admin.username}
            </h5>

            <button type="button" className="btn-close" onClick={onClose} />
          </div>

          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              {admin.role !== "master" && (
                <>
                  <h5 className="mb-3 mt-3">Exam Permission</h5>

                  <div className="row row-cols-4 g-3 gx-5">
                    {exams?.map((exam, index) => {
                      return (
                        <div className="col" key={exam._id}>
                          <div className="form-check">
                            <label
                              htmlFor={"m_checkbox" + (index + 1)}
                              className="form-check-label"
                            >
                              {exam.title}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedExams.includes(exam._id)}
                              onChange={onExamChange}
                              value={exam._id}
                              name={exam._id}
                              id={"m_checkbox" + (index + 1)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {admin.role !== Role.MASTER && admin.role !== Role.EXAM && (
                <>
                  <h5 className="my-3">Department Permissions</h5>

                  {Object.keys(departments)?.map((examId) => {
                    return (
                      <div key={examId} className="mb-3">
                        <h6>{getExamCode(examId)} departments</h6>

                        <div className="row row-cols-4 gx-5 gy-3">
                          {departments[examId]?.map((department) => {
                            return (
                              <div className="col" key={department._id}>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    {department.title}
                                  </label>

                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={selectedDepartments.includes(
                                      department._id
                                    )}
                                    onChange={onDepartmentChange}
                                    value={department._id}
                                    name={department._id}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              <div className="d-flex justify-content-end gap-3 my-4">
                <button className="btn btn-primary w-20">Save</button>

                <button
                  type="button"
                  className="btn btn-danger w-20"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
