import { useEffect, useState } from "react";
import { Submit } from "../../components/buttons";
import { Input } from "../../components/inputs";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";

const ViewReferrers = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [referrers, setReferrers] = useState([]);

  useEffect(() => {
    const fetchReferrers = async () => {
      try {
        const res = await instance.get(endpoints.referrerAll);
        setReferrers(res.data);
      } catch (err) {
        showToast({ type: "error", message: err.response.data.message });
      }
    };

    fetchReferrers();
  }, []);

  const createReferrer = async (event) => {
    event.preventDefault();

    if (!name) {
      return showToast({ type: "error", message: "Name is required" });
    }

    if (!email) {
      return showToast({ type: "error", message: "Email is required" });
    }

    const data = { name, username: email };

    try {
      const res = await instance.post(endpoints.referrerCreate, data);

      setReferrers([...referrers, res.data.data]);
      setName("");
      setEmail("");
      showToast({ type: "success", message: res.data.message });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const resetPassword = async (id) => {
    try {
      const res = await instance.put(endpoints.referrerReset, { id });
      showToast({ type: "success", message: res.data.message });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div className="container py-5">
      <div className="d-flex justify-content-evenly align-items-center">
        <h5 className="mb-3">Add Referrer</h5>
      </div>

      <form onSubmit={createReferrer}>
        <div className="row row-cols-3 gx-5 align-items-center">
          <div className="form-group col">
            <Input
              label="Name"
              placeholder="Name"
              value={name}
              onChange={setName}
            />
          </div>

          <div className="form-group col">
            <Input
              type="email"
              label="Email"
              placeholder="Email"
              value={email}
              onChange={setEmail}
            />
          </div>

          <div className="form-group col">
            <Submit className="mt-2" />
          </div>
        </div>
      </form>

      <div className="table-responsive mt-5">
        <table className="table table-stripped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>

          <tbody>
            {referrers.map((referrer, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{referrer.name}</td>
                <td>{referrer.username}</td>
                <td>
                  <button
                    className="action"
                    onClick={() => resetPassword(referrer._id)}
                  >
                    &#8635;
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewReferrers;
