import { default as classnames, default as classNames } from "classnames";
import { convertLatexToMarkup } from "mathlive";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { endpoints } from "../constants/Endpoints";
import { instance } from "../services/https/inceptors";
import { useFetch } from "../utilities/apis";
import { choiceLabel } from "../utilities/helpers";
import { showToast } from "../utilities/toast";
import { Thumb } from "./layouts";

const Error = ({ text }) => {
  if (!text) return null;
  return <div className="mt-3 text-danger">{text}</div>;
};

Error.propTypes = {
  text: PropTypes.string,
};

const Markup = ({ label, latex, className }) => {
  function markup() {
    const newLatex = latex.replace(/\/dash/g, "______");

    let markup = "";
    const words = newLatex.split(" ");

    words.forEach((word) => {
      let newWord = word;

      if (word.includes("/eqtn")) {
        try {
          newWord = convertLatexToMarkup(word.slice(5), {
            mathstyle: "displaystyle",
          });
        } catch (error) {
          console.log("Conversion Error", error.message);
        }
      }
      if (word.includes(".png")) {
        try {
          newWord = word;
        } catch (error) {
          console.log("image upload Error", error.message);
        }
      }

      markup += newWord + " ";
    });

    return { __html: markup };
  }

  if (!latex) return null;

  return (
    <p className={classnames(className)} style={{ whiteSpace: "pre-line" }}>
      <span className="font-medium"> {label && label} </span>
      <span dangerouslySetInnerHTML={markup()} />
    </p>
  );
};

Markup.propTypes = {
  label: PropTypes.string,
  latex: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const Options = ({ choices, onChange, language }) => {
  const onEdit = (choice) => () => {
    choice.image = "";
    onChange([...choices]);
  };

  return choices.map((choice, index) => (
    <div key={index} className="d-flex">
      <span>{choiceLabel(index)}</span>

      <div className="ms-3">
        <Markup className="mb-0" latex={choice.text?.[language]} />

        <div
          className={classnames(
            choice.image && index !== choices?.length - 1 && "mb-3",
            choice.text && choice.image && "mt-2"
          )}
        >
          <Thumb
            removable={onChange}
            filename={choice.image}
            onDelete={onEdit(choice)}
          />
        </div>
      </div>
    </div>
  ));
};

Options.propTypes = {
  choices: PropTypes.array,
  onChange: PropTypes.func,
};

const Result = ({ solution, language }) => {
  return (
    <div>
      <h6 className="font-semibold">Solution:</h6>
      {solution.text ? <Markup latex={solution.text[language]} /> : null}
    </div>
  );
};

Result.propTypes = {
  solution: PropTypes.object,
  onChange: PropTypes.func,
};

const Score = ({ label, positveMarks, negativeMarks, className }) => (
  <div className="d-flex flex-column mt-2" style={{ lineHeight: 0.5 }}>
    <p className={className}>
      <span className="font-semibold"> {label && "Total positve Marks: "}</span>
      {positveMarks} {positveMarks > 1 ? "Marks" : "Mark"}
    </p>
    <p className={className}>
      <span className="font-semibold">{label && "Total negative Marks: "}</span>
      {negativeMarks} {negativeMarks > 1 ? "Marks" : "Mark"}
    </p>
  </div>
);

Score.propTypes = {
  label: PropTypes.bool,
  positveMarks: PropTypes.string,
  negativeMarks: PropTypes.string,
  className: PropTypes.string,
};

/**
 * Displays question images
 */
const Images = ({ images, onChange }) => {
  function onEdit(image) {
    const filtered = images.filter((value) => value !== image);
    onChange([...filtered]);
  }

  return (
    <div
      className={classnames(
        "d-flex flex-wrap gap-3 justify-content-center mt-5",
        images?.length > 0 && "mb-3"
      )}
    >
      {images?.map((image, index) => (
        <Thumb
          key={index}
          removable={onChange}
          filename={image}
          onDelete={() => onEdit(image)}
        />
      ))}
    </div>
  );
};

Images.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

/**
 * Used for fetching data
 */

const Fetch = ({ url, onFetch, children }) => {
  const { get } = useFetch();

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    get(url, (error, response) => {
      if (error) return;
      onFetch(response);
      setFetching(false);
    });
  }, [get, onFetch, url]);

  return fetching ? <div>Loading...</div> : children;
};

Fetch.propTypes = {
  url: PropTypes.string,
  onFetch: PropTypes.func,
  children: PropTypes.node,
};

const ImageDelete = ({ page }) => {
  const [imageLink, setImageLink] = useState("");

  const handleDeleteImage = async () => {
    try {
      const res = await instance.delete(endpoints.deleteFromS3, {
        data: { link: imageLink },
      });

      if (res.status === 200) {
        showToast({
          type: "success",
          message: "Image deleted successfully",
        });

        setImageLink("");
      } else {
        showToast({
          type: "error",
          message: "Failed to delete image",
        });
      }
    } catch (err) {
      showToast({
        type: "error",
        message: "Failed to delete image",
      });
    }
  };

  return (
    <div>
      <h3 className="space-heading text-center">Image Deletion</h3>

      <div className="mt-5">
        <div className={classNames("mx-auto", page && "w-50")}>
          <p>
            This {page ? "page" : "section"} is used to delete images from the
            server. You can delete images by entering the image link and
            clicking on the delete button.
          </p>

          <p>
            <strong>Note:</strong> Please be careful while deleting the images.
          </p>

          <div className="mt-5 d-flex gap-2">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Image URL"
              value={imageLink}
              onChange={(e) => setImageLink(e.target.value)}
            />

            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDeleteImage}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ImageDelete.propTypes = {
  page: PropTypes.bool,
};

export { Error, Fetch, ImageDelete, Images, Markup, Options, Result, Score };
