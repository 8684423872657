import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Submit } from "../../components/buttons";
import { Input, Select } from "../../components/inputs";
import { endpoints } from "../../constants/Endpoints";
import { authUser } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { Admin as Role } from "../../utilities/constants";
import { showToast } from "../../utilities/toast";
import { EditAdminModal } from "./EditAdminModal";

const Admin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const [exams, setExams] = useState([]);
  const [selectedExams, setSelectedExams] = useState([]);
  const [departments, setDepartments] = useState({});
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [admins, setAdmins] = useState([]);
  const [adminStatus, setAdminStatus] = useState(1);

  const [show, setShow] = useState(false);
  const [adminToEdit, setAdminToEdit] = useState({});

  const sortOrder = ["master", "exam", "department", "senior", "support"];

  const { examPermissions, role: userRole } = useRecoilValue(authUser);

  useEffect(() => {
    const getAllowedExams = async () => {
      const query = `?examPermissions=${examPermissions.toString()}`;
      const res = await instance.get(`${endpoints.getAllowedExams}${query}`);
      setExams(res.data);
    };

    getAllowedExams();
  }, [examPermissions]);

  useEffect(() => {
    const getAllAdmins = async () => {
      const query = `?status=${parseInt(adminStatus) !== 1}`;
      const res = await instance.get(`${endpoints.getAllAdmins}${query}`);
      setAdmins(res.data);
    };

    getAllAdmins();
  }, [adminStatus]);

  function closeDialog() {
    setAdminToEdit({});
    setShow(false);
  }

  const getExamCode = (examId) => {
    return exams.find((exam) => exam._id === examId)?.code;
  };

  const onExamChange = async (event) => {
    const { checked, value } = event.target;

    if (checked) {
      setSelectedExams((prev) => [...prev, value]);

      if (role !== Role.MASTER && role !== Role.EXAM) {
        const res = await instance.get(
          `${endpoints.getDepartmentsByExam}?exam=${value}`
        );

        setDepartments((prev) => {
          return { ...prev, [value]: res.data };
        });
      }
    } else {
      setSelectedExams((prev) => prev.filter((item) => item !== value));

      if (role !== Role.MASTER && role !== Role.EXAM) {
        setDepartments((prev) => {
          const clone = { ...prev };
          delete clone[value];
          return clone;
        });
      }
    }
  };

  const onDepartmentChange = (event) => {
    const { checked, value } = event.target;

    if (checked) {
      setSelectedDepartments((prev) => [...prev, value]);
    } else {
      setSelectedDepartments((prev) => prev.filter((item) => item !== value));
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!username)
      return showToast({ type: "error", message: "Username is required" });

    if (!password)
      return showToast({ type: "error", message: "Password is required" });

    if (!role) return showToast({ type: "error", message: "Role is required" });

    const data = {
      username,
      password,
      role,
    };

    if (role === Role.EXAM) {
      data.examPermissions = selectedExams;
    } else if (role !== Role.MASTER && role !== Role.EXAM) {
      data.examPermissions = selectedExams;
      data.departmentPermissions = selectedDepartments;
    }

    const endpoint = {
      [Role.MASTER]: endpoints.makeMasterAdmin,
      [Role.EXAM]: endpoints.makeExamAdmin,
      [Role.DEPARTMENT]: endpoints.makeDepartmentAdmin,
      [Role.SENIOR]: endpoints.makeSeniorAdmin,
      [Role.SUPPORT]: endpoints.makeSupportAdmin,
    };

    try {
      const res = await instance.post(endpoint[role], data);
      setAdmins([...admins, res.data.data]);
      setUsername("");
      setPassword("");
      setRole("");
      setSelectedExams([]);
      setSelectedDepartments([]);
      showToast({ type: "success", message: "Successfully added the admin" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const onEditAdmin = (admin) => {
    setAdminToEdit(admin);
    setShow(true);
  };

  const onUpdated = (admin) => {
    const clone = [...admins];
    const index = clone.findIndex((adm) => adm._id === admin._id);

    if (index > -1) {
      clone[index] = admin;
      setAdmins(clone);
    }

    closeDialog();
  };

  const onReset = (admin) => {
    const clone = [...admins];
    const index = clone.findIndex((adm) => adm._id === admin._id);

    if (index > -1) {
      clone[index] = admin;
      setAdmins(clone);
    }
  };

  const actionChange = (admin, data) => async () => {
    const key = Object.keys(data)[0];
    data._id = admin._id;
    let text;

    if (key === "role") {
      text = `Change ${admin.username}'s role to ${data[key]} admin?`;
    } else if (key === "disabled") {
      text = `${data[key] ? "Disable" : "Enable"} ${admin.username}'s account?`;
    } else {
      text = `Reset ${admin.username}'s password?`;
    }

    if (window.confirm(text)) {
      await instance.put(endpoints.updateAdmin, data);
      if (key === "password") {
        return alert(`Success: Password reset to ${data[key]}`);
      }
      admin[key] = data[key];
      setAdmins([...admins]);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-evenly align-items-center">
        <h5 className="mb-3"> Add Admin</h5>
      </div>

      <form onSubmit={onSubmit}>
        <div className="row row-cols-3 gx-5 gy-3">
          <div className="form-group col">
            <Input
              label="Username"
              placeholder="Username"
              value={username}
              onChange={setUsername}
            />
          </div>

          <div className="form-group col">
            <Input
              label="Password"
              placeholder="Password"
              value={password}
              onChange={setPassword}
            />
          </div>

          <div className="form-group col">
            <Select
              label="Role"
              value={role}
              onChange={(e) => {
                setRole(e);
                setSelectedExams([]);
                setSelectedDepartments([]);
              }}
            >
              {userRole === "master" && (
                <option value={Role.MASTER}>Master admin</option>
              )}
              <option value={Role.EXAM}>Exam admin</option>
              <option value={Role.DEPARTMENT}>Department admin</option>
              <option value={Role.SENIOR}>Senior admin</option>
              <option value={Role.SUPPORT}>Support admin</option>
            </Select>
          </div>
        </div>

        {role && role !== Role.MASTER && (
          <>
            <h5 className="my-3">Exam permissions</h5>

            <div className="row row-cols-4 gx-5 gy-3">
              {exams?.map((exam) => {
                return (
                  <div className="col" key={exam._id}>
                    <div className="form-check">
                      <label className="form-check-label">{exam.title}</label>

                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectedExams.includes(exam._id)}
                        onChange={onExamChange}
                        value={exam._id}
                        name={exam._id}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {role && role !== Role.MASTER && role !== Role.EXAM && (
          <>
            <h5 className="my-3">Department Permissions</h5>

            {Object.keys(departments)?.map((examId) => {
              return (
                <div key={examId} className="mb-3">
                  <h6>{getExamCode(examId)} departments</h6>

                  <div className="row row-cols-4 gx-5 gy-3">
                    {departments[examId]?.map((department) => {
                      return (
                        <div className="col" key={department._id}>
                          <div className="form-check">
                            <label className="form-check-label">
                              {department.title}
                            </label>

                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedDepartments.includes(
                                department._id
                              )}
                              onChange={onDepartmentChange}
                              value={department._id}
                              name={department._id}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}

        <div className="row justify-content-center my-5 row-cols-4">
          <div className="col">
            <Submit />
          </div>
        </div>
      </form>

      <br />

      <div className="table-responsive mt-5">
        <div className="d-flex justify-content-end">
          <Select value={adminStatus} onChange={setAdminStatus} label="Status">
            <option value={1}>Active</option>
            <option value={0}>Disable</option>
          </Select>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Username</th>
              <th scope="col">Role</th>
              <th scope="col">Exam Permission</th>
              <th scope="col">Department Permission</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {admins
              .toSorted(
                (a, b) => sortOrder.indexOf(a.role) - sortOrder.indexOf(b.role)
              )
              ?.map((admin, index) => (
                <tr key={admin._id}>
                  <th scope="row">{index + 1}</th>
                  <td>{admin.username}</td>
                  <td style={{ textTransform: "uppercase" }}>{admin.role}</td>
                  <td>
                    <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
                      {admin.examPermissions?.map((adm) => {
                        return (
                          <div
                            style={{
                              background: "#79a8ff",
                              padding: "4px 6px",
                              borderRadius: "5px",
                              fontSize: 13,
                            }}
                            key={adm._id}
                          >
                            {adm.title}
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
                      {admin.departmentPermissions?.map((dep) => {
                        return (
                          <div
                            style={{
                              background: "#79a8ff",
                              padding: "4px 6px",
                              borderRadius: "5px",
                              fontSize: 13,
                            }}
                            key={dep._id}
                          >
                            {dep.title}
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div className="flex">
                      {admin.role !== Role.SUPPORT && (
                        <>
                          {admin.role === Role.MASTER && (
                            <button
                              className="action down"
                              onClick={actionChange(admin, {
                                role: Role.EXAM,
                              })}
                            >
                              &#x02193;
                            </button>
                          )}
                          {admin.role === Role.EXAM && (
                            <button
                              className="action down"
                              onClick={actionChange(admin, {
                                role: Role.DEPARTMENT,
                              })}
                            >
                              &#x02193;
                            </button>
                          )}
                          {admin.role === Role.DEPARTMENT && (
                            <button
                              className="action down"
                              onClick={actionChange(admin, {
                                role: Role.SENIOR,
                              })}
                            >
                              &#x02193;
                            </button>
                          )}
                          {admin.role === Role.SENIOR && (
                            <button
                              className="action down"
                              onClick={actionChange(admin, {
                                role: Role.SUPPORT,
                              })}
                            >
                              &#x02193;
                            </button>
                          )}
                        </>
                      )}
                      {admin.role !== Role.MASTER && (
                        <>
                          {admin.role === Role.SUPPORT && (
                            <button
                              className="action up"
                              onClick={actionChange(admin, {
                                role: Role.SENIOR,
                              })}
                            >
                              &#x02191;
                            </button>
                          )}
                          {admin.role === Role.SENIOR && (
                            <button
                              className="action up"
                              onClick={actionChange(admin, {
                                role: Role.DEPARTMENT,
                              })}
                            >
                              &#x02191;
                            </button>
                          )}
                          {admin.role === Role.DEPARTMENT && (
                            <button
                              className="action up"
                              onClick={actionChange(admin, {
                                role: Role.EXAM,
                              })}
                            >
                              &#x02191;
                            </button>
                          )}
                          {admin.role === Role.EXAM && (
                            <button
                              className="action up"
                              onClick={actionChange(admin, {
                                role: Role.MASTER,
                              })}
                            >
                              &#x02191;
                            </button>
                          )}
                        </>
                      )}
                      <button
                        className="action back mx-2"
                        onClick={actionChange(admin, {
                          password: admin.username,
                        })}
                      >
                        &#x02190;
                      </button>
                      {admin.disabled && (
                        <button
                          className="action check"
                          onClick={actionChange(admin, { disabled: false })}
                        >
                          &#x02713;
                        </button>
                      )}
                      {!admin.disabled && (
                        <button
                          className="action delete"
                          onClick={actionChange(admin, { disabled: true })}
                        >
                          &#x003A7;
                        </button>
                      )}

                      {admin.role !== Role.MASTER && (
                        <button
                          className="action edit mx-2"
                          onClick={() => onEditAdmin(admin)}
                        >
                          &curren;
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <EditAdminModal
        admin={adminToEdit}
        exams={exams}
        show={show}
        onClose={closeDialog}
        onReset={onReset}
        onUpdated={onUpdated}
      />
    </>
  );
};

const AdminsPage = () => (
  <div className="h-100   overflow-auto">
    <div className="container py-5 ">
      <div className="row justify-content-center">
        <Admin />
      </div>
    </div>
  </div>
);
export default AdminsPage;
