import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../components/buttons";
import { DateInput, Select } from "../../components/inputs";
import { Loader } from "../../components/Loaders";
import { ALL_PRODUCT_MODES_ARRAY } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";

const Statistics = () => {
  const { department, exam } = useParams();

  const [mode, setMode] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [payments, setPayments] = useState([]);

  const fetchStatistics = async () => {
    setLoading(true);

    try {
      let url = `${endpoints.statistics}/?exam=${exam}&department=${department}`;

      if (mode) url += `&mode=${mode}`;
      if (dateFrom) url += `&dateFrom=${dateFrom}`;
      if (dateTo) url += `&dateTo=${dateTo}`;

      const res = await instance.get(url);

      const { documents, totalAmount, totalDocuments } = res.data;

      setProducts(totalDocuments);
      setRevenue(totalAmount);
      setPayments(documents);
    } catch (error) {
      showToast({
        type: "error",
        message: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row row-cols-3 mt-5 mb-3">
        <div className="col">
          <Select label="Mode" value={mode} onChange={setMode}>
            {ALL_PRODUCT_MODES_ARRAY.map((key) => (
              <option key={key} value={key}>
                {" "}
                {key.split("_").join(" ")}{" "}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <DateInput
            label="Date from"
            value={dateFrom}
            max={new Date().toISOString().split("T")[0]}
            onChange={setDateFrom}
          />
        </div>

        <div className="col">
          <DateInput
            label="Date to"
            value={dateTo}
            min={dateFrom}
            max={new Date().toISOString().split("T")[0]}
            onChange={setDateTo}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <Button
          disabled={loading}
          color="success"
          width="25"
          onClick={fetchStatistics}
        >
          Go
        </Button>
      </div>

      <div className="my-4">
        <div className="row row-cols-3">
          <div className="col text-center">
            <h4>Total Products:</h4>
            <h5>{products}</h5>
          </div>

          <div className="col text-center">
            <h4>Total Revenue:</h4>
            <h5>{revenue}</h5>
          </div>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        payments &&
        payments.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Plan</th>
                <th>Amount</th>
                <th>Referral code</th>
              </tr>
            </thead>

            <tbody>
              {payments.map((payment, index) => (
                <tr key={payment._id}>
                  <td>{index + 1}</td>
                  <td>{payment.user?.name}</td>
                  <td>{payment.user?.username}</td>
                  <td>{payment.user?.phone}</td>
                  <td>{payment.title}</td>
                  <td>{payment.discount}</td>
                  <td>{payment.referral}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      )}
    </div>
  );
};

export default Statistics;
